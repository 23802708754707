<div class="row">
    <div class="col-xs-12">
        <h3 style="height:32px;" class="skills-container d-flex justify-content-between">
            <strong>
                <span class="pr-3">{{skillHeading}}</span>
                <span *ngIf="!isEditSkills" class="fas fa-edit pointer d-inline-block text-primary"
                    style="height:30px; font-size: 15px;" (click)="onEditSkill()" tooltip="Edit"
                    container="true"></span>
            </strong>
            <span
                *ngIf="skillType !== 'education' && skillType !== 'experience' && skillType !== 'certification' && isEditSkills"
                style="border-bottom: 0px; bottom: 5px;" class="position-relative">
                <input [(ngModel)]="addNewSkill" [typeahead]="_setTypeAheadList('skills')" typeaheadOptionField="skill"
                    class="d-inline-block mx-3" placeholder="Enter Skill" (typeaheadOnSelect)="onSkillSelect($event)"
                    style="font-size: 15px;" [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="8">
                <button class="btn btn-primary position-relative px-3 py-2" (click)="onAddSkill()"
                    style="bottom: 6px;">Add</button>
            </span>
        </h3>
    </div>
</div>
<div class="row candidate-profile-assets">
    <!-- Skills -->
    <div *ngIf="skillType !== 'education' && skillType !== 'experience' && skillType !== 'certification'"
        class="col-xs-12 text-left skills-container">
        <div class="row" cdkDropList [cdkDropListData]="skills" (cdkDropListDropped)="drop($event)">
            <div class="col-xs-12 pl-2" *ngIf="skills.length > 0">
                <div *ngFor="let skill of skills; index as $index; trackBy:$index" cdkDrag
                    [cdkDragDisabled]="isEditSkills" id="skill-{{skillType}}" [cdkDragData]="skill">
                    <div class="d-flex align-items-center pb-2">
                        <div *ngIf="!isEditSkills && skill.competency && skill.competency != ''" class="d-flex pl-3"
                            style="cursor: move;">
                            <div *ngIf="skill.atleastOrMinOrMax != '' && (skill.fromYear || skill.toYear)"
                                class="pr-2 text-capitalize font-bold">{{skill.atleastOrMinOrMax}}</div>
                            <div *ngIf="skill.fromYear || skill.toYear" class="">
                                <span class="font-bold pr-1" *ngIf="skill.fromYear">{{skill.fromYear}}</span>
                                <span class="font-bold pr-1"
                                    *ngIf="skill.plusTo != 'to' || skill.toYear">{{skill.plusTo}}</span>
                                <span class="font-bold pr-2" *ngIf="skill.toYear">{{skill.toYear}}</span>
                                <span class="mr-2">years of experience in</span>
                            </div>
                            <div class="mr-2 font-bold" [ngClass]="{'text-success other-skill': skill.competency.toLowerCase() == 'other'}">{{skill.competency}}</div>
                            <div *ngIf="skill.mandatory || skill.favorite || skill.niceToHave" class="pr-2">
                                <span class="pr-2 fas fa-asterisk color-red" *ngIf="skill.mandatory" tooltip="Mandatory"
                                    container="true"></span>
                                <span class="pr-2 fas fa-heart text-danger" *ngIf="skill.favorite" tooltip="Favorite"
                                    container="true"></span>
                                <span class="pr-2 fas fa-thumbs-up text-primary" *ngIf="skill.niceToHave"
                                    tooltip="Nice to have" container="true"></span>
                            </div>
                        </div>
                        <div *ngIf="isEditSkills"
                            class="d-flex justify-content-around mt-2 candidate-skills-item px-0 col-xs-11">
                            <select id="alreastMinMax-{{$index}}" class="form-control candidate-skill-range"
                                name="atleastMinMax-{{$index}}" [(ngModel)]="skill.atleastOrMinOrMax">
                                <option *ngFor="let range of ranges; index as $rangeIndex; trackBy: $rangeIndex"
                                    [value]="range.value">
                                    {{range.name}}</option>
                            </select>
                            <input type="number" step="0.1" placeholder="Years" style="width:7%" min="0"
                                [(ngModel)]="skill.fromYear">
                            <select id="plusTo" class="form-control candidate-skill-range" name="plusTo-{{$index}}"
                                [(ngModel)]="skill.plusTo" style="width:7%">
                                <option value=""></option>
                                <option value="+">+</option>
                                <option value="to">to</option>
                            </select>
                            <input *ngIf="skill.plusTo === 'to'" type="number" step="0.1" min="0" placeholder="Years"
                                style="width:7%" [(ngModel)]="skill.toYear">
                            <span style="margin-top: 7px">years of experience in</span>
                            <input type="text" name="skill" class="d-inline-block candidate-typeahead"
                                [ngClass]="{'text-success other-skill': skill.competency.toLowerCase() == 'other'}"
                                [(ngModel)]="skill.competency" style="width:20%" placeholder="Skill" autocomplete="off"
                                (typeaheadOnSelect)="onSkillSelect($event)" [typeahead]="_setTypeAheadList('skills')"
                                [typeaheadOptionsInScrollableView]="5" typeaheadOptionField="skill"
                                [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="8">
                            <span class="pt-1 d-flex justify-content-end mt-2" style="width: 15%">
                                <span *ngIf="!skill.niceToHave && skill.mandatory"
                                    class="cursor-pointer fas fa-asterisk color-red pr-2 pt-1" 
                                    tooltip="Mandatory" container="true"
                                    (click)="updateMandatory(skill)" style="font-size: 14px;">
                                </span>
                                <span *ngIf="!skill.niceToHave && !skill.mandatory" class="cursor-pointer" tooltip="Mandatory" container="true" (click)="updateMandatory(skill)">
                                    <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                                        <path class="cls-asterisk" fill="red"
                                            d="m28.89 20.91-5-2.91 4.87-2.86a3.1 3.1 0 0 0 1.14-1.08 3 3 0 0 0-4.09-4.15L21 12.76V7a3 3 0 0 0-6 0v5.76l-4.85-2.85a3 3 0 1 0-3 5.18l5 2.91-4.95 2.86a3.1 3.1 0 0 0-1.14 1.08 3 3 0 0 0 4.09 4.14L15 23.24v5.66a3 3 0 0 0 2 2.94A3 3 0 0 0 21 29v-5.76l4.85 2.85a3 3 0 1 0 3-5.18ZM28.24 24a1 1 0 0 1-1.37.36L19 19.75V29a1 1 0 0 1-2 0v-9.25l-7.87 4.61a1 1 0 0 1-1-1.72L16 18l-7.9-4.64a1 1 0 1 1 1-1.72l7.9 4.61V7a1 1 0 0 1 2 0v9.25l7.87-4.62a1 1 0 0 1 1 1.72L20 18l7.9 4.64a1 1 0 0 1 .34 1.36" />
                                        <path fill="none" d="M0 0h36v36H0z" />
                                    </svg>
                                </span>
                                <span class="cursor-pointer text-danger fa-heart pr-2 mr-1 pt-1"
                                    [ngClass]="{'fas': skill.favorite, 'far': !skill.favorite}"
                                    (click)="skill.favorite = !skill.favorite" tooltip="Favorite" container="true">
                                </span>
                                <span *ngIf="!skill.mandatory"
                                    class="cursor-pointer text-primary skill-nice-to-have-icon fa-thumbs-up pr-2 mr-1 pt-1"
                                    [ngClass]="{'fas': skill.niceToHave, 'far': !skill.niceToHave}"
                                    (click)="updateNiceToHave(skill)" tooltip="Nice to have" container="true">
                                </span>
                                <span class="fas fa-times text-danger cursor-pointer delete-skill"
                                    tooltip="Delete Skill" (click)="deleteSkill($index)"></span>
                            </span>
                        </div>
                        <div *ngIf="(skills.length - 1 > $index) && isEditSkills" container="true"
                            tooltip="{{isEditSkills ? 'Click to change priority' : ''}}"
                            [ngClass]="{'mt-2': isEditSkills, 'mt-0': !isEditSkills}"
                            class="cursor-pointer col-xs-1 mt-2 d-flex align-items-center justify-content-start px-0 text-muted font-bold">
                            <span *ngIf="skill.priority" (click)="skill.priority = !skill.priority"
                                [ngClass]="{'pointer-events-none': !isEditSkills}">(OR)</span>
                            <span *ngIf="!skill.priority" (click)="skill.priority = !skill.priority"
                                [ngClass]="{'pointer-events-none': !isEditSkills}">(AND)</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xs-6" *ngIf="skills.length == 0">
                <strong><span class="asset-value pl-3" style="font-weight: 500;">No skills available.</span></strong>
            </div>

            <div *ngIf="isEditSkills" class=" col-xs-12 d-flex justify-content-end mb-2 mt-5">
                <button class="btn btn-danger mr-3 skill-button" (click)="resetSkills()">Cancel</button>
                <button class="btn btn-primary skill-button" (click)="editSkills()">Save</button>
            </div>
        </div>
    </div>

    <!-- Education -->
    <div *ngIf="skillType === 'education'" class="col-xs-12 text-left skills-container">
        <div *ngIf="skills.length > 0">
            <div *ngFor="let education of skills; index as $index; trackBy:$index"
                class="d-flex align-items-center pb-2">
                <div *ngIf="!isEditSkills && education.degreeName && education.degreeName != ''" class="d-flex pl-3">
                    <div class="mr-2 font-bold">{{education.degreeName}}</div>
                    <div class="mr-1" *ngIf="education.instituteName && education.instituteName != ''">in</div>
                    <div class="mr-2 font-bold" *ngIf="education.instituteName && education.instituteName != ''">
                        {{education.instituteName}}</div>
                    <div *ngIf="education.mandatory || education.favorite || education.niceToHave" class="pr-2">
                        <span class="pr-2 fas fa-asterisk color-red" *ngIf="education.mandatory" tooltip="Mandatory"
                                container="true"></span>
                        <span class="pr-2 fas fa-heart text-danger" *ngIf="education.favorite" tooltip="Favorite"
                            container="true"></span>
                        <span class="pr-2 fas fa-thumbs-up text-primary" *ngIf="education.niceToHave"
                            tooltip="Nice to have" container="true"></span>
                    </div>
                </div>
                <div *ngIf="isEditSkills && context==='job'" class="d-flex justify-content-around mt-2 pl-0 col-xs-11">
                    <input [(ngModel)]="education.degreeName" [typeahead]="_setTypeAheadList('education')"
                        typeaheadOptionField="name" class="d-inline-block" style="width:35%" placeholder="Education Type"
                        [tooltip]="education.degreeName" container="true">
                    <span style="margin-top: 7px">in</span>
                    <input type="text" name="department" [typeahead]="_setTypeAheadList('department')"
                        typeaheadOptionField="name" class="d-inline-block" [(ngModel)]="education.instituteName"
                        style="width:35%" placeholder="Field of study" [tooltip]="education.instituteName"
                        container="true">
                    <span class="text-primary pt-1 d-flex justify-content-end">
                        <span *ngIf="!education.niceToHave && education.mandatory"
                            class="cursor-pointer fas fa-asterisk color-red pr-2 pt-1" 
                            tooltip="Mandatory" container="true"
                            (click)="updateMandatory(education)" style="font-size: 14px;">
                        </span>
                        <span *ngIf="!education.niceToHave && !education.mandatory" class="cursor-pointer" tooltip="Mandatory" container="true" (click)="updateMandatory(education)">
                            <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                                <path class="cls-asterisk" fill="red"
                                    d="m28.89 20.91-5-2.91 4.87-2.86a3.1 3.1 0 0 0 1.14-1.08 3 3 0 0 0-4.09-4.15L21 12.76V7a3 3 0 0 0-6 0v5.76l-4.85-2.85a3 3 0 1 0-3 5.18l5 2.91-4.95 2.86a3.1 3.1 0 0 0-1.14 1.08 3 3 0 0 0 4.09 4.14L15 23.24v5.66a3 3 0 0 0 2 2.94A3 3 0 0 0 21 29v-5.76l4.85 2.85a3 3 0 1 0 3-5.18ZM28.24 24a1 1 0 0 1-1.37.36L19 19.75V29a1 1 0 0 1-2 0v-9.25l-7.87 4.61a1 1 0 0 1-1-1.72L16 18l-7.9-4.64a1 1 0 1 1 1-1.72l7.9 4.61V7a1 1 0 0 1 2 0v9.25l7.87-4.62a1 1 0 0 1 1 1.72L20 18l7.9 4.64a1 1 0 0 1 .34 1.36" />
                                <path fill="none" d="M0 0h36v36H0z" />
                            </svg>
                        </span>
                        <span class="cursor-pointer text-danger fa-heart pr-2 mr-1 pt-1"
                            [ngClass]="{'fas': education.favorite, 'far': !education.favorite}"
                            (click)="education.favorite = !education.favorite" tooltip="Favorite" container="true">
                        </span>
                        <span *ngIf="!education.mandatory"
                            class="cursor-pointer text-primary skill-nice-to-have-icon fa-thumbs-up pr-2 mr-1 pt-1"
                            [ngClass]="{'fas': education.niceToHave, 'far': !education.niceToHave}"
                            (click)="updateNiceToHave(education)" tooltip="Nice to have" container="true">
                        </span>
                        <span class="fas fa-times text-danger cursor-pointer delete-skill" tooltip="Delete Education"
                            (click)="deleteSkill($index)"></span>
                    </span>
                </div>
                <div *ngIf="(skills.length - 1 > $index) && isEditSkills" container="true"
                    tooltip="{{isEditSkills ? 'Click to change priority' : ''}}"
                    class="cursor-pointer col-xs-1 d-flex align-items-center justify-content-start px-0 text-muted font-bold"
                    [ngClass]="{'mt-2': isEditSkills, 'mt-0': !isEditSkills}">
                    <span *ngIf="education.priority" (click)="education.priority = !education.priority"
                        [ngClass]="{'pointer-events-none': !isEditSkills}">(OR)</span>
                    <span *ngIf="!education.priority" (click)="education.priority = !education.priority"
                        [ngClass]="{'pointer-events-none': !isEditSkills}">(AND)</span>
                </div>
            </div>
        </div>
        <div *ngIf="skills.length == 0 && !isEditSkills">
            <strong><span class="asset-value pl-3" style="font-weight: 500;">No Education available.</span></strong>
        </div>
        <div class="col-xs-6" *ngIf="isEditSkills">
            <span class="text-primary d-inline-block m-3 cursor-pointer" (click)="newSkill()"><span
                    class="fas fa-plus"></span> Add Education</span>
        </div>
        <div *ngIf="isEditSkills" class="d-flex justify-content-end mb-2">
            <button class="btn btn-danger mr-3 skill-button" (click)="resetSkills()">Cancel</button>
            <button class="btn btn-primary skill-button" (click)="editSkills()">Save</button>
        </div>
    </div>

    <!-- Experience -->
    <div *ngIf="skillType === 'experience'" class="col-xs-12 text-left skills-container">
        <div *ngIf="skills.length > 0">
            <div *ngFor="let experience of skills; index as $index; trackBy: $index"
                class="d-flex align-items-center pb-2">
                <div *ngIf="!isEditSkills && experience.title && experience.title != ''" class="d-flex pl-3">
                    <div *ngIf="experience.atleastOrMinOrMax != '' && (experience.fromYear || experience.toYear)"
                        class="pr-2 text-capitalize font-bold">{{experience.atleastOrMinOrMax}}</div>
                    <div *ngIf="experience.fromYear || experience.toYear" class="">
                        <span class="font-bold pr-1">{{experience.fromYear}}</span>
                        <span class="font-bold pr-1"
                            *ngIf="experience.plusTo != 'to' || experience.toYear">{{experience.plusTo}}</span>
                        <span class="font-bold pr-2">{{experience.toYear}}</span>
                        <span class="mr-2">years of experience in</span>
                    </div>
                    <div class="mr-2 font-bold">{{experience.title}}</div>
                    <div *ngIf="experience.mandatory || experience.favorite || experience.niceToHave" class="pr-2">
                        <span class="pr-2 fas fa-asterisk color-red" *ngIf="experience.mandatory" tooltip="Mandatory"
                            container="true"></span>
                        <span class="pr-2 fas fa-heart text-danger" *ngIf="experience.favorite" tooltip="Favorite"
                            container="true"></span>
                        <span class="pr-2 fas fa-thumbs-up text-primary" *ngIf="experience.niceToHave"
                            tooltip="Nice to have" container="true"></span>
                    </div>
                </div>
                <div *ngIf="isEditSkills && context==='job'" class="d-flex justify-content-around mt-2 col-xs-11 pl-0">
                    <select id="alreastMinMax-{{$index}}" class="form-control candidate-skill-range"
                        name="atleastMinMax-{{$index}}" [(ngModel)]="experience.atleastOrMinOrMax">
                        <option *ngFor="let range of ranges; index as $rangeIndex; trackBy: $rangeIndex"
                            [value]="range.value">
                            {{range.name}}</option>
                    </select>
                    <input type="number" step="0.1" placeholder="Years" style="width:7%" min="0"
                        [(ngModel)]="experience.fromYear">
                    <select id="plusTo" class="form-control candidate-skill-range" name="plusTo-{{$index}}"
                        [(ngModel)]="experience.plusTo" style="width:7%">
                        <option value=""></option>
                        <option value="+">+</option>
                        <option value="to">to</option>
                    </select>
                    <input *ngIf="experience.plusTo === 'to'" type="number" step="0.1" min="0" placeholder="Years"
                        style="width:7%" [(ngModel)]="experience.toYear">
                    <span style="margin-top: 7px">years of experience in</span>
                    <input [(ngModel)]="experience.title" [typeahead]="_setTypeAheadList('experience')"
                        typeaheadOptionField="name" class="d-inline-block" style="width:20%" placeholder="Title"
                        name="title" placeholder="Title" [tooltip]="experience.title" container="true">
                    <span class="text-primary d-flex justify-content-end align-items-center" style="width:15%">
                        <span *ngIf="!experience.niceToHave && experience.mandatory"
                            class="cursor-pointer fas fa-asterisk color-red pr-2 pt-1" 
                            tooltip="Mandatory" container="true"
                            (click)="updateMandatory(experience)" style="font-size: 14px;">
                        </span>
                        <span *ngIf="!experience.niceToHave && !experience.mandatory" class="cursor-pointer pt-3" tooltip="Mandatory" container="true" (click)="updateMandatory(experience)">
                            <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                                <path class="cls-asterisk" fill="red"
                                    d="m28.89 20.91-5-2.91 4.87-2.86a3.1 3.1 0 0 0 1.14-1.08 3 3 0 0 0-4.09-4.15L21 12.76V7a3 3 0 0 0-6 0v5.76l-4.85-2.85a3 3 0 1 0-3 5.18l5 2.91-4.95 2.86a3.1 3.1 0 0 0-1.14 1.08 3 3 0 0 0 4.09 4.14L15 23.24v5.66a3 3 0 0 0 2 2.94A3 3 0 0 0 21 29v-5.76l4.85 2.85a3 3 0 1 0 3-5.18ZM28.24 24a1 1 0 0 1-1.37.36L19 19.75V29a1 1 0 0 1-2 0v-9.25l-7.87 4.61a1 1 0 0 1-1-1.72L16 18l-7.9-4.64a1 1 0 1 1 1-1.72l7.9 4.61V7a1 1 0 0 1 2 0v9.25l7.87-4.62a1 1 0 0 1 1 1.72L20 18l7.9 4.64a1 1 0 0 1 .34 1.36" />
                                <path fill="none" d="M0 0h36v36H0z" />
                            </svg>
                        </span>
                        <span class="cursor-pointer text-danger fa-heart pr-2 mr-1 pt-1"
                            [ngClass]="{'fas': experience.favorite, 'far': !experience.favorite}"
                            (click)="experience.favorite = !experience.favorite" tooltip="Favorite" container="true">
                        </span>
                        <span *ngIf="!experience.mandatory"
                            class="cursor-pointer text-primary skill-nice-to-have-icon fa-thumbs-up pr-2 mr-1 pt-1"
                            [ngClass]="{'fas': experience.niceToHave, 'far': !experience.niceToHave}"
                            (click)="updateNiceToHave(experience)" tooltip="Nice to have" container="true">
                        </span>
                        <span class="fas fa-times text-danger cursor-pointer delete-skill" tooltip="Delete Experience"
                            (click)="deleteSkill($index)"></span>
                    </span>
                </div>
                <div *ngIf="(skills.length - 1 > $index) && isEditSkills" container="true"
                    tooltip="{{isEditSkills ? 'Click to change priority' : ''}}"
                    class="cursor-pointer col-xs-1 d-flex align-items-center justify-content-start px-0 text-muted font-bold"
                    [ngClass]="{'mt-2 pt-1': isEditSkills, 'mt-0': !isEditSkills}">
                    <span *ngIf="experience.priority" (click)="experience.priority = !experience.priority"
                        [ngClass]="{'pointer-events-none': !isEditSkills}">(OR)</span>
                    <span *ngIf="!experience.priority" (click)="experience.priority = !experience.priority"
                        [ngClass]="{'pointer-events-none': !isEditSkills}">(AND)</span>
                </div>
            </div>
        </div>
        <div *ngIf="skills.length == 0 && !isEditSkills">
            <strong><span class="asset-value pl-3" style="font-weight: 500;">No Experience available.</span></strong>
        </div>
        <div class="col-xs-6" *ngIf="isEditSkills">
            <span class="text-primary d-inline-block m-3 cursor-pointer" (click)="newSkill()"><span
                    class="fas fa-plus"></span> Add Experience</span>
        </div>
        <div *ngIf="isEditSkills" class="d-flex justify-content-end">
            <button class="btn btn-danger mr-3 skill-button" (click)="resetSkills()">Cancel</button>
            <button class="btn btn-primary skill-button" (click)="editSkills()">Save</button>
        </div>
    </div>

    <!-- Certification -->
    <div *ngIf="skillType === 'certification'" class="col-xs-12 text-left skills-container">
        <div *ngFor="let certification of skills; index as $index; trackBy:$index">
            <div *ngIf="skills.length > 0" class="d-flex align-items-center pb-2">
                <div *ngIf="!isEditSkills && certification.degreeName && certification.degreeName != ''"
                    class="d-flex pl-3">
                    <div class="mr-2 font-bold">{{certification.degreeName}}</div>
                    <div class="mr-1" *ngIf="certification.instituteName && certification.instituteName != ''">in</div>
                    <div class="mr-2 font-bold"
                        *ngIf="certification.instituteName && certification.instituteName != ''">
                        {{certification.instituteName}}</div>
                    <div *ngIf="certification.mandatory || certification.favorite || certification.niceToHave"
                        class="pr-2">
                        <span class="pr-2 fas fa-asterisk color-red" *ngIf="certification.mandatory" tooltip="Mandatory"
                            container="true"></span>
                        <span class="pr-2 fas fa-heart text-danger" *ngIf="certification.favorite" tooltip="Favorite"
                            container="true"></span>
                        <span class="pr-2 fas fa-thumbs-up text-primary" *ngIf="certification.niceToHave"
                            tooltip="Nice to have" container="true"></span>
                    </div>
                </div>
                <div *ngIf="isEditSkills && context==='job'" class="d-flex justify-content-around mt-2 col-xs-11 pl-0">
                    <input [(ngModel)]="certification.degreeName" [typeahead]="_setTypeAheadList('certifications')"
                        typeaheadOptionField="name" class="d-inline-block" style="width:35%" placeholder="Education"
                        name="degree" placeholder="Certification name" [tooltip]="certification.degreeName"
                        container="true">
                    <span style="margin-top: 7px">in</span>
                    <input type="text" name="skill" [typeahead]="_setTypeAheadList('skills')"
                        typeaheadOptionField="skill" class="d-inline-block" [(ngModel)]="certification.instituteName"
                        style="width:35%" placeholder="Skill" [typeaheadScrollable]="true"
                        [typeaheadOptionsInScrollableView]="8">
                    <span class="text-primary pt-1 d-flex justify-content-end">
                        <span *ngIf="!certification.niceToHave && certification.mandatory"
                            class="cursor-pointer fas fa-asterisk color-red pr-2 pt-1" 
                            tooltip="Mandatory" container="true"
                            (click)="updateMandatory(certification)" style="font-size: 14px;">
                        </span>
                        <span *ngIf="!certification.niceToHave && !certification.mandatory" class="cursor-pointer" tooltip="Mandatory" container="true" (click)="updateMandatory(certification)">
                            <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                                <path class="cls-asterisk" fill="red"
                                    d="m28.89 20.91-5-2.91 4.87-2.86a3.1 3.1 0 0 0 1.14-1.08 3 3 0 0 0-4.09-4.15L21 12.76V7a3 3 0 0 0-6 0v5.76l-4.85-2.85a3 3 0 1 0-3 5.18l5 2.91-4.95 2.86a3.1 3.1 0 0 0-1.14 1.08 3 3 0 0 0 4.09 4.14L15 23.24v5.66a3 3 0 0 0 2 2.94A3 3 0 0 0 21 29v-5.76l4.85 2.85a3 3 0 1 0 3-5.18ZM28.24 24a1 1 0 0 1-1.37.36L19 19.75V29a1 1 0 0 1-2 0v-9.25l-7.87 4.61a1 1 0 0 1-1-1.72L16 18l-7.9-4.64a1 1 0 1 1 1-1.72l7.9 4.61V7a1 1 0 0 1 2 0v9.25l7.87-4.62a1 1 0 0 1 1 1.72L20 18l7.9 4.64a1 1 0 0 1 .34 1.36" />
                                <path fill="none" d="M0 0h36v36H0z" />
                            </svg>
                        </span>
                        <span class="cursor-pointer text-danger fa-heart pr-2 mr-1 pt-1"
                            [ngClass]="{'fas': certification.favorite, 'far': !certification.favorite}"
                            (click)="certification.favorite = !certification.favorite" tooltip="Favorite"
                            container="true">
                        </span>
                        <span *ngIf="!certification.mandatory"
                            class="cursor-pointer text-primary skill-nice-to-have-icon fa-thumbs-up pr-2 mr-1 pt-1"
                            [ngClass]="{'fas': certification.niceToHave, 'far': !certification.niceToHave}"
                            (click)="updateNiceToHave(certification)" tooltip="Nice to have" container="true">
                        </span>
                        <span class="fas fa-times text-danger cursor-pointer delete-skill"
                            tooltip="Delete Certification" (click)="deleteSkill($index)"></span>
                    </span>
                </div>
                <div *ngIf="(skills.length - 1 > $index) && isEditSkills" container="true"
                    tooltip="Click to change priority"
                    class="cursor-pointer col-xs-1 mt-2 d-flex align-items-center justify-content-start px-0 text-muted font-bold">
                    <span *ngIf="certification.priority"
                        (click)="certification.priority = !certification.priority">(OR)</span>
                    <span *ngIf="!certification.priority"
                        (click)="certification.priority = !certification.priority">(AND)</span>
                </div>
            </div>
        </div>
        <div *ngIf="skills.length == 0 && !isEditSkills">
            <strong><span class="asset-value pl-3" style="font-weight: 500;">No Certification available.</span></strong>
        </div>
        <div class="col-xs-6" *ngIf="isEditSkills">
            <span class="text-primary d-inline-block m-3 cursor-pointer" (click)="newSkill()"><span
                    class="fas fa-plus"></span> Add Certification</span>
        </div>
        <div *ngIf="isEditSkills" class="d-flex justify-content-end mt-4">
            <button class="btn btn-danger mr-3 skill-button" (click)="resetSkills()">Cancel</button>
            <button class="btn btn-primary skill-button" (click)="editSkills()">Save</button>
        </div>
    </div>
</div>

<ng-template #addEditCandidateSkill let-item="item" let-index="index">
    <div class="row d-flex">
        <div class="mr-3 radio my-0">
            <input type="radio" [(ngModel)]="item.mandatory" id="candidateSkillMandatory" name="candidateSkill"
                (change)="onCandidateSkillUpdate(index, item, 'mandatory')" [value]="true">
            <label for="candidateSkillMandatory">Mandatory Skill</label>
        </div>
        <div class="radio mt-1 mb-0">
            <input type="radio" [(ngModel)]="item.mandatory" id="candidateSkillFavorite" name="candidateSkill"
                (change)="onCandidateSkillUpdate(index, item, 'favorite')" [value]="false">
            <label for="candidateSkillFavorite">Favorite Skill</label>
        </div>
    </div>
</ng-template>