import { Injectable, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AlertsAndNotificationsServiceAjs } from 'src/app/public/app/services/alertsAndNotificationsService';
import { AngularModalService } from './modal.service';


declare var bootbox: any;

@Injectable({
  providedIn: 'root'
})
export class AlertAndNotificationsService {

  constructor(@Inject('alertsAndNotificationsServiceAjs') public alertAndNotificationsAjs: AlertsAndNotificationsServiceAjs,
    @Inject('$rootScope') public $rootScope, 
    @Inject(AngularModalService) public modalService: AngularModalService,
    private toastr: ToastrService) {
  }

  showNotification() {
    console.log("Check Service");
  }

  showBannerMessage(messageData, messageType) {
    if (messageData === 'Session Expired.') {
      this.$rootScope.$emit("CallLogoutMethod", {
        logoutnow: true
      });
    } else {
      this.alertAndNotificationsAjs.showBannerMessage(messageData, messageType);
    }
  }

  showDialog(message) {
    var dialog = bootbox.dialog({
      message: `<span class="loading-message"> ${message} <i class="fa fa-spinner fa-spin"></i></span>`,
      closeButton: false
    });
    setTimeout(() => {
      dialog.modal('hide');
    }, 1500)
  }

  showAlert(title, message, type) {
    bootbox.hideAll();
    let alertClass = `alert-${type}`;
    let iconClass = this._getBootBoxIconClass(type);
    bootbox.alert({
      closeButton: false,
      title: `<div class='alert ${alertClass} mb-0'><i class='${iconClass}'></i><strong>  ${title} </strong></div>`,
      message: message,
      className: "z-index-1100",
      buttons: {
        ok: {
          label: 'Ok',
          className: 'btn-info'
        }
      },
      callback: () => {
        this.modalService.addModalOpenClassToBodyIfAnyModalIsOpen();
    }
    });
  }

  showConfirm(title, message, type, isButtonLabelYesOrNo, successCallback, cancelCallback) {
    bootbox.hideAll();
    let alertClass = `alert-${type}`;
    let iconClass = this._getBootBoxIconClass(type);
    let confirmButtonLabel = 'Ok';
    let cancelButtonLabel = 'Cancel';
    if (isButtonLabelYesOrNo) {
      confirmButtonLabel = 'Yes';
      cancelButtonLabel = 'No';
    }
    bootbox.confirm({
      closeButton: false,
      title: `<div class='alert ${alertClass} mb-0'><i class='${iconClass}'></i><strong>  ${title} </strong></div>`,
      message: message,
      className: "zIndex1060",
      backdrop: true,
      onEscape: false,
      buttons: {
        confirm: {
          label: confirmButtonLabel,
          className: 'btn-info'
        },
        cancel: {
          label: cancelButtonLabel,
          className: 'btn-danger'
        }
      },
      callback: (result) => {
        if (result) {
          if (successCallback) {
            successCallback();
          }
        } else {
          if (cancelCallback) {
            cancelCallback();
          }
        }
      }
    });
  }

  showCustomConfirm(successButton, cancelButton, title, message, type, successCallback, cancelCallback) {
    bootbox.hideAll();
    let alertClass = `alert-${type}`;
    let iconClass = this._getBootBoxIconClass(type);

    bootbox.confirm({
      closeButton: false,
      title: `<div class='alert ${alertClass} mb-0'><i class='${iconClass}'></i><strong>  ${title} </strong></div>`,
      message: message,
      className: "zIndex1060",
      backdrop: true,
      onEscape: false,
      buttons: {
        confirm: {
          label: successButton,
          className: 'btn-info'
        },
        cancel: {
          label: cancelButton,
          className: 'btn-danger'
        }
      },
      callback: (result) => {
        if (result) {
          if (successCallback) {
            successCallback();
          }
        } else {
          if (cancelCallback) {
            cancelCallback();
          }
        }
      }
    });
  }

  showAlertWithCallBack(title, message, type, successCallback) {
    bootbox.hideAll();
    let alertClass = `alert-${type}`;
    let iconClass = this._getBootBoxIconClass(type);
    bootbox.alert({
      closeButton: false,
      title: `<div class='alert ${alertClass} mb-0'><i class='${iconClass}'></i><strong>  ${title} </strong></div>`,
      message: message,
      className: "zIndex1060",
      buttons: {
        ok: {
          label: 'Ok',
          className: 'btn-info'
        }
      },
      callback: (result) => {
        if (successCallback) {
          successCallback();
        }
      }
    });
  }

  showToastMessage(message, messageType) {
    if (messageType == 'success') {
      this.toastr.success(message);
    } else if (messageType == 'warning') {
      this.toastr.warning(message);
    } else if (messageType == 'danger') {
      this.toastr.error(message);
    }
  }

  _getBootBoxIconClass(type) {
    if (type == 'warning') {
      return 'fas fa-exclamation-triangle fa-fw fa-lg';
    } else if (type == "danger") {
      return 'fa fa-times-circle fa-fw fa-lg';
    } else if (type == "success") {
      return 'fa fa-check fa-fw fa-lg';
    }
  }

}
