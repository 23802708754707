<div class="col-xs-12 pt-1 pb-1" *ngIf="!isLoading">
    <div class="main-box clearfix col-xs-12 mb-0">
        <div class="main-box-body clearfix col-xs-12 px-0">
            <div class="col-xs-12 px-0 pl-4 ml-2 pt-2 my-4 d-flex justify-content-between">
                <div>
                    <h2 class="requisition-description-heading font-bold">Job Description</h2>
                </div>
                <div class="pull-right pr-5 mt-2">
                    <button class="btn btn-danger mr-3" (click)="revertToPreviousVersion()">Revert to Previous</button>
                    <button class="btn btn-primary" (click)="revertToOriginalVersion()">Revert to Original</button>
                </div>
            </div>
            <div class="col-xs-12 mb-3 pl-4">
                <div class="col-xs-12 pl-2">
                    <div class="col-xs-5 pl-0">
                        <!-- job title -->
                        <div class="form-group">
                            <label>Title <em>*</em> : </label>
                            <a *ngIf="currentJobStatus !== 'DRAFT'" class="pl-2" (click)="showJobProfile()" style="color: black; font-size: 100%; font-weight: bold;" tooltip="{{jobTitle}}">
                                <span class="control-label">{{jobTitle}}</span>
                            </a>
                            <i class="fas fa-pencil-alt cursor-pointer" tooltip="Edit Title" (click)="editTitleFlag = !editTitleFlag" *ngIf="isUploadRequisition"></i>
                            <input *ngIf="(currentJobStatus == 'DRAFT') && (editTitleFlag || !isUploadRequisition)" type="text" class="form-control" id="jobTitle" [(ngModel)]="jobTitle"
                                    name="jobTitle" style="border-width: 1px;width: 100%">
                        </div>
                    </div>
                    <div class="col-xs-5">
                        <!-- Location -->
                        <div class="form-group">
                            <label>Location : </label>
                            <span tooltip="{{jobLocation}}" style="font-size: 12px; font-weight: bold; cursor:pointer;">
                                {{jobLocation}}
                            </span>
                            <i class="fas fa-pencil-alt cursor-pointer" tooltip="Edit Location" (click)="updateLocation()"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 px-0 pl-4 pr-5 ml-2 d-flex align-items-end">
                <div class="col-xs-6 px-0 pr-3">
                    <div class="col-xs-12 p-3 skill-category-container">
                        <div class="col-xs-12 pl-0" [ngClass]="{'pb-3': addAllSkills != ''}">
                            <div class="col-xs-12 pt-2">
                                <div style="display: inline;" class="mr-3 font-bold">Quick Add:</div>
                                <div class="upload-req-radio mr-2" style="display: inline;">
                                    <input type="radio" name="addAllSkills" id="addEducation" value="education"
                                        [(ngModel)]="addAllSkills" (change)="onAllSkillsChanges()">
                                    <label for="addEducation" class="mb-0">
                                        Education
                                    </label>
                                </div>
                                <div class="upload-req-radio mr-2" style="display: inline;">
                                    <input type="radio" name="addAllSkills" id="addExperience" value="experience"
                                        [(ngModel)]="addAllSkills" (change)="onAllSkillsChanges()">
                                    <label for="addExperience" class="mb-0">
                                        Experience
                                    </label>
                                </div>
                                <div class="upload-req-radio" style="display: inline;">
                                    <input type="radio" name="addAllSkills" id="addSkills" value="skill"
                                        [(ngModel)]="addAllSkills" (change)="onAllSkillsChanges()">
                                    <label for="addSkills" class="mb-0">
                                        Skills
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 px-0" [ngClass]="{'pb-2': addAllSkills != ''}">
                            <div class="col-xs-12 pr-0 skills-container d-flex align-items-center">
                                <!-- Add Education -->
                                <div *ngIf="addAllSkills === 'education'" class="d-flex justify-content-start mt-2 pl-0">
                                    <input [(ngModel)]="newSkill.degreeName" [typeahead]="allEducationsList"
                                        typeaheadOptionField="name" class="d-inline-block" placeholder="Education Type"
                                        [tooltip]="newSkill.degreeName" container="true">
                                    <span style="margin-top: 7px" class="mx-4">in</span>
                                    <input type="text" name="department" [typeahead]="educationDepartmentList"
                                        typeaheadOptionField="name" class="d-inline-block"
                                        [(ngModel)]="newSkill.instituteName" placeholder="Field of study"
                                        [tooltip]="newSkill.instituteName" container="true">
                                </div>
                                <!-- Add Experience -->
                                <div *ngIf="addAllSkills === 'experience'"
                                    class="d-flex justify-content-between mt-2 col-xs-11 pl-0">
                                    <select id="alreastMinMax-{{$index}}" class="form-control candidate-skill-range"
                                        name="atleastMinMax-{{$index}}" [(ngModel)]="newSkill.atleastOrMinOrMax">
                                        <option *ngFor="let range of ranges; index as $rangeIndex; trackBy: $rangeIndex"
                                            [value]="range.value">
                                            {{range.name}}</option>
                                    </select>
                                    <input type="number" step="0.1" placeholder="Years" style="width:7%" min="0"
                                        [(ngModel)]="newSkill.fromYear">
                                    <select id="plusTo" class="form-control candidate-skill-range" name="plusTo-{{$index}}"
                                        [(ngModel)]="newSkill.plusTo" style="width:7%">
                                        <option value=""></option>
                                        <option value="+">+</option>
                                        <option value="to">to</option>
                                    </select>
                                    <input *ngIf="newSkill.plusTo === 'to'" type="number" step="0.1" min="0"
                                        placeholder="Years" style="width:7%" [(ngModel)]="newSkill.toYear">
                                    <span style="margin-top: 10px" class="mx-2">years of experience in</span>
                                    <input [(ngModel)]="newSkill.title" [typeahead]="experiencesList"
                                        typeaheadOptionField="name" class="d-inline-block" style="width:25%"
                                        placeholder="Title" name="title" placeholder="Title" [tooltip]="newSkill.title"
                                        container="true">
                                </div>
                                <!-- Add Skill -->
                                <!-- <div *ngIf="addAllSkills === 'skill'"
                                    class="d-flex justify-content-between mt-2 pl-0 col-xs-4">
                                    <input [(ngModel)]="newSkill.competency" [typeahead]="allExistingSkills"
                                        typeaheadOptionField="skill" class="d-inline-block" placeholder="Enter Skill"
                                        (typeaheadOnSelect)="onSkillSelect($event)" style="font-size: 15px; width: 100%;"
                                        [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="8">
                                </div>
                                <div class="px-3 py-2 position-relative" *ngIf="addAllSkills && addAllSkills != ''">
                                    <button class="btn btn-primary" (click)="addNewSkill(addAllSkills)">Add
                                        {{addAllSkills.slice(0,1).toUpperCase() + addAllSkills.slice(1)}}</button>
                                </div> -->
                                <!-- Add Skill Type ahead all -->
                                <div *ngIf="addAllSkills === 'skill'"
                                    class="d-flex justify-content-between mt-2 pl-0 col-xs-4">
                                    <input 
                                        [(ngModel)]="newSkill.competency" 
                                        [typeahead]="allSkillsObservable"
                                        [typeaheadAsync]="true"
                                        typeaheadOptionField="displayName" 
                                        class="d-inline-block" 
                                        placeholder="Enter Skill"
                                        (typeaheadOnSelect)="onSkillSelect($event)" 
                                        style="font-size: 15px; width: 100%;"
                                        [typeaheadOptionsLimit]="8"
                                        container="body"
                                        [typeaheadScrollable]="true"
                                        [typeaheadOptionsInScrollableView]="8"
                                    >
                                </div>
                                <div class="px-3 py-2 position-relative" *ngIf="addAllSkills && addAllSkills != ''">
                                    <button class="btn btn-primary" (click)="addNewSkill(addAllSkills)">Add
                                        {{addAllSkills.slice(0,1).toUpperCase() + addAllSkills.slice(1)}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="col-xs-12" cdkDropListGroup>
                <div class="col-xs-12">
                    <!-- Education -->
                    <div class="col-xs-12 col-lg-6">
                        <app-add-edit-candidate-skill [skills]="skills.educations" skillType="education" context="job"
                            mode="edit"></app-add-edit-candidate-skill>
                    </div>
                    <!-- Experience -->
                    <div class="col-xs-12 col-lg-6">
                        <app-add-edit-candidate-skill [skills]="skills.experiences" [allExistingSkills]="allExistingSkills"
                            (saveCallback)="skillsSaveCallback($event)" skillType="experience" context="job"
                            mode="edit"></app-add-edit-candidate-skill>
                    </div>
                </div>
                <div class="col-xs-12">
                    <!-- Technical skills -->
                    <div class="col-xs-12 col-lg-6">
                        <app-add-edit-candidate-skill [skills]="skills.technicalSkills" [allExistingSkills]="allExistingSkills"
                            (saveCallback)="skillsSaveCallback($event)" skillType="technical" context="job"
                            mode="edit"></app-add-edit-candidate-skill>
                    </div>
                    <!-- Operational Skills -->
                    <div class="col-xs-12 col-lg-6">
                        <app-add-edit-candidate-skill [skills]="skills.operationalSkills"
                            [allExistingSkills]="allExistingSkills" (saveCallback)="skillsSaveCallback($event)"
                            skillType="operational" context="job" mode="edit"></app-add-edit-candidate-skill>
                    </div>
                </div>
                <div class="col-xs-12">
                    <!-- Soft Skills -->
                    <div class="col-xs-12 col-lg-6">
                        <app-add-edit-candidate-skill [skills]="skills.softSkills" [allExistingSkills]="allExistingSkills"
                            (saveCallback)="skillsSaveCallback($event)" skillType="soft" context="job"
                            mode="edit"></app-add-edit-candidate-skill>
                    </div>
        
                    <!-- Other Skills -->
                    <div class="col-xs-12 col-lg-6">
                        <app-add-edit-candidate-skill [skills]="skills.extraSkills" [allExistingSkills]="allExistingSkills"
                            (saveCallback)="skillsSaveCallback($event)" skillType="other" context="job"
                            mode="edit"></app-add-edit-candidate-skill>
                    </div>
                </div>
                <div class="col-xs-12">
                    <!-- Certifications -->
                    <div class="col-xs-12 col-lg-6">
                        <app-add-edit-candidate-skill [skills]="skills.certifications" skillType="certification" context="job"
                            mode="edit"></app-add-edit-candidate-skill>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-xs-12 pt-4" *ngIf="!isLoading">
    <div class="main-box clearfix col-xs-12">
        <div class="main-box-body clearfix col-xs-12">
            <h2 class="requisition-description-heading my-4 pb-1 font-bold">Benefits</h2>
            <!-- Benefits note -->
            <div class="form-group mb-0">
                <app-ngx-editor [placeholder]="'Enter text here...'" [spellcheck]="true" [(ngModel)]="benefitsNote"
                    [config]="editorConfig">
                </app-ngx-editor>
                <!-- <div class="error error-gap" *ngIf="!benefitsNotes">
                <small class=""><em>*</em>
                    External Note is required
                </small>
            </div> -->
            </div>
        </div>
    </div>
</div>

<div class="main-box-body clearfix col-lg-12" *ngIf="isLoading">
    <div class="text-center" style="font-size: 20px;">
        <span class="pr-2">Loading</span>
        <span class="fa fa-spinner fa-spin"></span>
    </div>
</div>
