<div class="row">
    <div class="col-lg-12">
        <div id="content-header" class="clearfix">
            <div class="pull-left">
                <ol class="breadcrumb">
                    <li><a (click)="redirectTo('missioncontrol.dashboard')">Home</a></li>
                    <li class="active"><span>Manage Master Lists</span></li>
                </ol>
                <h1 class="heading-css">Others</h1>
            </div>
        </div>
    </div>
</div>
<div class="col-xs-12">
    <div class="main-box clearfix">
        <div>
            <tabset>
                <tab *ngFor="let tab of entityTabs; let i = index"
                     [heading]="tab.title"
                     [active]="tab.active"
                     (selectTab)="tab.active = true"
                     (deselect)="tab.active = false">
                  <!-- <div *ngIf="tab.id === 'skill'">
                    <app-manage-skills-page></app-manage-skills-page>
                  </div> -->
                  <div *ngIf="tab.id === 'degree'">
                    <div *ngIf="!isDegreesEntitiesLoading">
                      <app-manage-requisition-entities-page
                          requisitionEntity="degrees"
                          [entitiesData]="degreesEntities"
                          (successCallback)="refreshDegreesCallback($event)">
                      </app-manage-requisition-entities-page>
                    </div>
                    <div *ngIf="isDegreesEntitiesLoading" class="d-flex justify-content-center align-items-center" style="height:500px">
                        <div class="loading-spinner">
                          <span class="mr-2">Loading</span>
                          <span class="fa fa-spinner fa-spin"></span>
                        </div>
                    </div>
                  </div>
                  <div *ngIf="tab.id === 'fieldOfStudy'">
                    <div *ngIf="!isFieldOfStudyEntitiesLoading">
                      <app-manage-requisition-entities-page
                          requisitionEntity="fieldOfStudy"
                          [entitiesData]="fieldOfStudyEntities"
                          (successCallback)="refreshFieldOfStudyCallback($event)">
                      </app-manage-requisition-entities-page>
                    </div>
                    <div *ngIf="isFieldOfStudyEntitiesLoading" class="d-flex justify-content-center align-items-center" style="height:500px">
                      <div class="loading-spinner">
                        <span class="mr-2">Loading</span>
                        <span class="fa fa-spinner fa-spin"></span>
                      </div>
                  </div>
                  </div>
                  <div *ngIf="tab.id === 'role'">
                    <div *ngIf="!isRolesLoading">
                      <app-manage-requisition-entities-page
                          requisitionEntity="roles"
                          [entitiesData]="rolesEntities"
                          (successCallback)="refreshRolesCallback($event)">
                      </app-manage-requisition-entities-page>
                    </div>
                    <div *ngIf="isRolesLoading" class="d-flex justify-content-center align-items-center" style="height:500px">
                      <div class="loading-spinner">
                        <span class="mr-2">Loading</span>
                        <span class="fa fa-spinner fa-spin"></span>
                      </div>
                  </div>
                  </div>
                  <div *ngIf="tab.id === 'certificates'">
                    <div *ngIf="!isCertificatesLoading">
                      <app-manage-requisition-entities-page
                          requisitionEntity="certificates"
                          [entitiesData]="certificatesEntities"
                          (successCallback)="refreshCertificatesCallback($event)">
                      </app-manage-requisition-entities-page>
                    </div>
                    <div *ngIf="isCertificatesLoading" class="d-flex justify-content-center align-items-center" style="height:500px">
                      <div class="loading-spinner">
                        <span class="mr-2">Loading</span>
                        <span class="fa fa-spinner fa-spin"></span>
                      </div>
                  </div>
                  </div>
                </tab>
              </tabset>
        </div>
    </div>
</div>