import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import {
    CdkDragDrop,
    CdkDrag,
    CdkDropList,
    CdkDropListGroup,
    moveItemInArray,
    transferArrayItem,
} from '@angular/cdk/drag-drop';
declare var bootbox: any;

@Component({
    selector: 'app-add-edit-candidate-skill',
    templateUrl: './add-edit-candidate-skill.component.html',
    styleUrls: ['./add-edit-candidate-skill.component.css']
})
export class AddEditCandidateSkillComponent implements OnInit {

    @Input() userId;
    @Input() skills;
    @Input() skillType;
    @Input() context;
    @Input() mode;
    @Input() currentSkills;
    @Input() originalSkills;
    @Input() allExistingSkills;
    @Output() saveCallback = new EventEmitter<any>();
    @Output() cancelCallback = new EventEmitter<any>();

    skillHeading: any;
    skillsCopy: any;
    selected: any = undefined;
    searchResults: any = [];
    selectedSkillType: any;
    skillTypes: any = [];
    timePeriods: any = [];
    allSkills: any;
    skillsTypeAheadList: any = [];
    ranges: any = [];
    resetSkillsList: any = [];
    draggable: any;
    addNewSkill: any;
    addNewSkillObj: any;
    isEditSkills: boolean = false;
    viewSkillDetails: boolean = true;
    expandSkills: boolean;

    constructor(
        private alertsAndNotificationsService: AlertAndNotificationsService
    ) {
    }

    ngOnChanges(changes) {
        if (changes && changes.allExistingSkills && !changes.allExistingSkills.firstChange && changes.allExistingSkills.currentValue && changes.allExistingSkills.previousValue) {
            if (changes.allExistingSkills.currentValue.length != changes.allExistingSkills.previousValue) {
                this.setAllExistingSkills(changes.allExistingSkills.currentValue);
            }
        }

        if(changes && changes.skills && !changes.skills.firstChange && changes.skills.currentValue && changes.skills.previousValue) {
            if (changes.allExistingSkills && (changes.allExistingSkills.currentValue.length != changes.allExistingSkills.previousValue)) {
                this.skillsCopy = this.skills.slice(0,);
            }
        }
    }

    ngOnInit() {

        this.skills.forEach(skill => {
            skill.atleastOrMinOrMax = "atleast",
                skill.plusTo = ""
        });
        this.skillHeading = this._getSkillsHeading();
        this.skillsCopy = angular.copy(this.skills);
        // if (this.mode && this.mode === 'edit') {
        //     this.isEditSkills = true;
        // }
        this._setAllSkills();
        this.selectedSkillType = "education";
        this.skillTypes = [
            { name: "Education", value: "education" },
            { name: "Experience", value: "experience" },
            { name: "Technical Skills", value: "technical" },
            { name: "Operational Skills", value: "operational" },
            { name: "Soft Skills", value: "soft" },
            { name: "Other Skills", value: "other" },
            { name: "Certifications", value: "certifications" },
        ];
        let skills = {
            education: [],
            experience: [],
            technical: [],
            operational: [],
            soft: [],
            other: [],
            certifications: []
        };
        this.currentSkills = angular.copy(skills);
        this.originalSkills = angular.copy(skills);

        this._setExperienceYears();
        this._massageSkillsData();
        this.ranges = [
            { name: "", value: "" },
            { name: "Atleast", value: "atleast" },
            { name: "Min", value: "min" },
            { name: "Max", value: "max" }
        ];
        this.resetSkillsList = [...this.skills];
    }

    _setTypeAheadList(type) {
        switch (type) {
            case 'education':
                return this.allSkills.education;
            case 'experience':
                return this.allSkills.experience;
            case 'skills':
                return this.allSkills.allExistingSkills;
            case 'certifications':
                return this.allSkills.certifications;
            case 'department':
                return this.allSkills.department;
            default:
                return this.allSkills.other;
        }
    }

    _getSkillsHeading() {
        switch (this.skillType) {
            case "education":
                return "Education";
            case "experience":
                return "Experience";
            case "technical":
                return "Technical Skills";
            case "operational":
                return "Operational Skills";
            case "soft":
                return "Soft Skills";
            case "other":
                return "Other Skills";
            case "certification":
                return "Certifications"
            default:
                return "Technical Skills";
        }
    }

    _setAllSkills() {
        this.allSkills = {
            education: [
                { id: 1, name: 'B Tech', department: "", mandatory: false, favorite: false, experience: { years: 1, months: 0 } },
                { id: 2, name: 'M Tech', department: "", mandatory: false, favorite: false, experience: { years: 0, months: 10 } },
                { id: 3, name: 'PHD', department: "", mandatory: false, favorite: false, experience: { years: 2, months: 1 } },
                { id: 4, name: 'Degree', department: "", mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
                { id: 5, name: 'B Tech or equivalent experience', department: "", mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
                { id: 6, name: 'B Pharm', department: "", mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
                { id: 7, name: 'M Pharm', department: "", mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
                { id: 8, name: 'M Tech or equivalent experience', department: "", mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
                { id: 9, name: 'B Com', department: "", mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
                { id: 10, name: 'PHD or equivalent experience', department: "", mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
                { id: 11, name: 'Bachelors or equivalent experience', department: "", mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
                { id: 12, name: 'Masters or equivalent experience', department: "", mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
            ],
            department: [
                { id: 1, name: "Computer Science Engineering" },
                { id: 2, name: "Civil Engineering" },
                { id: 3, name: "Information Technology" },
                { id: 4, name: "Cyber security" },
                { id: 5, name: "Machine Learning" },
                { id: 6, name: "Computer Graphics" },
                { id: 7, name: "Web Development" },
                { id: 8, name: "Software Engineering" },
                { id: 9, name: "Arts and Sciences" },
                { id: 10, name: "Artificial Intelligence" },
                { id: 11, name: "Computational Biology" },
                { id: 12, name: "Computer Networks" }
            ],
            experience: [
                { id: 1, name: 'Software Engineer', mandatory: false, favorite: false, experience: { years: 1, months: 0 } },
                { id: 2, name: 'Developer', mandatory: false, favorite: false, experience: { years: 0, months: 10 } },
                { id: 3, name: 'Front end developer', mandatory: false, favorite: false, experience: { years: 2, months: 1 } },
                { id: 4, name: 'Angular developer', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
                { id: 5, name: 'Angular Js', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
                { id: 6, name: 'Type Script', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
                { id: 7, name: 'JS', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
                { id: 8, name: 'TS', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
                { id: 9, name: 'JQuery', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
                { id: 10, name: 'Node', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
                { id: 11, name: 'Bootstrap', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
                { id: 12, name: 'Java', mandatory: false, favorite: false, experience: { years: 0, months: 0 } }
            ],
            certifications: [
                { id: 1, name: 'AWS Certified Solutions Architect', mandatory: false, favorite: false, experience: { years: 1, months: 0 } },
                { id: 2, name: 'Certified Ethical Hacker', mandatory: false, favorite: false, experience: { years: 0, months: 10 } },
                { id: 3, name: 'Azure Solutions Architect Expert', mandatory: false, favorite: false, experience: { years: 2, months: 1 } },
                { id: 4, name: 'Google Professional Cloud Architect', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
                { id: 5, name: 'Certified Information Security Manager', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
                { id: 6, name: 'CompTIA Security+', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
                { id: 7, name: 'Oracle Certified Professional', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
                { id: 8, name: 'Java SE 11 Developer', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
                { id: 9, name: 'CISSP', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
                { id: 10, name: 'Certified Kubernetes Administrator', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
                { id: 11, name: 'Certified Web Professional', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
                { id: 12, name: 'Google Mobile Web Specialist', mandatory: false, favorite: false, experience: { years: 0, months: 0 } }
            ]
        };

        if (this.allExistingSkills && this.allExistingSkills.length > 0) {
            this.allExistingSkills.forEach(skill => {
                skill.competency = skill.skill;
            });
            this.setAllExistingSkills(this.allExistingSkills);
        } else {
            this.setAllExistingSkills(null);
        }
    }

    _setExperienceYears() {
        var a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
        var b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
        for (let i = 1; i < 100; i++) {
            let num: any = i;
            if ((num = num.toString()).length > 9) return 'overflow';
            let n: any = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
            if (!n) return; var str = '';
            str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
            str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
            str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
            str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
            str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + '' : '';
            var yearText = "";
            if (num > 1) {
                yearText = "years of ";
            } else {
                yearText = "year of ";
            }
            var stringText = str + yearText;
            var numText = num + " " + yearText;
            this.timePeriods.push({ name: stringText, value: { years: i, months: 0 } });
            this.timePeriods.push({ name: numText, value: { years: i, months: 0 } });
        }
    }

    _massageSkillsData() {
        angular.forEach(this.skillTypes, (type, key) => {
            angular.forEach(this.allSkills[type.value], (skill, key) => {
                this._setSkillDisplayValue(skill);
            });
            angular.forEach(this.skills[type.value], (skill, key) => {
                this._setSkillDisplayValue(skill);
            });
            angular.forEach(this.currentSkills[type.value], (skill, key) => {
                this._setSkillDisplayValue(skill);
            });
            angular.forEach(this.originalSkills[type.value], (skill, key) => {
                this._setSkillDisplayValue(skill);
            });
        });
    }

    _setSkillDisplayValue(skill) {
        var skillDisplayValue = "";
        skillDisplayValue = skillDisplayValue + skill.name;
        skill.skillDisplayValue = skillDisplayValue;
    }

    getSkill(searchText) {
        this.searchResults = [];
        searchText = searchText.toLowerCase();
        if (this._searchStringHasYearsInfoButNoSkill(searchText)) {
            return this._getYearsForSearchString(searchText);
        }
        var periodObject;
        var preText = "";
        var isSearchStringHasYears = false;
        var actualSearchText = "";
        if (!isSearchStringHasYears) {
            actualSearchText = searchText;
        }
        var validSkills = this.allSkills[this.selectedSkillType].filter((skill) => {
            return skill.name.toLowerCase().includes(actualSearchText);
        });

        if (isSearchStringHasYears && validSkills.length > 0) {
            angular.forEach(validSkills, (skill, key) => {
                this.searchResults.push({ displayName: preText + skill.name, experience: periodObject.value, mandatory: false, favorite: false, name: skill.name, id: skill.id });
            });
        } else if (validSkills.length > 0) {
            angular.forEach(validSkills, (skill, key) => {
                this.searchResults.push({ displayName: skill.name, experience: skill.experience, mandatory: false, favorite: false, name: skill.name, id: skill.id });
            });
        }
        this.skillsTypeAheadList = this.searchResults;
    }

    _searchStringHasYearsInfoButNoSkill(searchText) {
        var yearsResult = this.timePeriods.filter((period) => {
            return period.name.includes(searchText);
        });
        return yearsResult.length > 0;
    }

    _getYearsForSearchString(searchText) {
        var yearsResult = this.timePeriods.filter((period) => {
            return period.name.includes(searchText);
        });
        var yearsObjectArray = [];
        angular.forEach(yearsResult, (year, key) => {
            yearsObjectArray.push({ displayName: year.name });
        });
        return yearsObjectArray;
    }

    onSkillSelect(e: TypeaheadMatch): void {
        this._addSkill(e);
        this.addNewSkillObj = e.item;
    }

    _addSkill(selectedSkill) {
        if (selectedSkill.id !== undefined) {
            this._setSkillDisplayValue(selectedSkill);
            this.selected = undefined;
        }
    }

    newSkill() {
        this.expandSkills = true;
        this.skills.push(
            {
                "competency": "",
                "experience": 1,
                "status": "MATCH",
                "mandatory": false,
                "favorite": false,
                "atleastOrMinOrMax": "atleast",
                "plusTo": ""
            }
        );
    }

    _setNewItem(newItem: any) {
        return newItem && newItem.id ? {
            ...newItem,
            competency: newItem.skill || newItem.name
        } : {
            id: null,
            name: this.addNewSkill,
            atleastOrMinOrMax: '',
            competency: this.addNewSkill,
            mandatory: false,
            favorite: false,
            experience: { years: 0, months: 0 }
        };
    }
    
    setSkillsCopy(skills) {
        this.skillsCopy = skills.slice(0,);
    }

    onAddSkill() {
        if (this.addNewSkill && this.addNewSkill != '') {
            if (this.addNewSkillObj && this.addNewSkillObj.id) {
                this.skills.push(this._setNewItem(this.addNewSkillObj));
                this.setSkillsCopy(this.skills);
                if(this.allSkills.allExistingSkills) {
                    this.allSkills.allExistingSkills = this.allSkills.allExistingSkills.filter(skill => skill.id != this.addNewSkillObj.id);
                }
            } else {
                let newItem = this._setNewItem(null);
                this.skills.push(newItem);
                this.setSkillsCopy(this.skills);
            }
            this.addNewSkillObj = {};
            this.addNewSkill = '';
        } else {
            this.alertsAndNotificationsService.showAlert('Skill Name Empty', "Enter Skill Name to Add", 'warning');
        }
    }

    onSkillUpdate(skillToUpdate) {
        this._setSkillDisplayValue(skillToUpdate);
        var skillIndex = _.findIndex(this.skills[this.selectedSkillType], (skill: any) => {
            return skillToUpdate.id === skill.id && skillToUpdate.skillDisplayValue === skill.skillDisplayValue;
        });
        this.skills[this.selectedSkillType].splice(skillIndex, 1, skillToUpdate);
    }

    onDeleteSkill(skill) {
        this._deleteSkill(skill);
        if(this.allSkills.allExistingSkills) {
            this.allSkills.allExistingSkills = this.allSkills.allExistingSkills.push(skill);
        }
    }

    _deleteSkill(skillToDelete) {
        _.remove(this.skills[this.selectedSkillType], (skill: any) => {
            return skillToDelete.id == skill.id && skillToDelete.skillDisplayValue == skill.skillDisplayValue;
        });
    }

    onResetSkillsClick(resetType) {
        var message = "";
        if (resetType == "previous") {
            message = "Going back to Requisition Detail of version saved previously. Are you sure to continue?";
        } else if (resetType == 'original') {
            message = "Going back to Requisition Detail of version saved when Requisition was ‘Activated’. Are you sure to continue?";
        }
        bootbox.confirm({
            closeButton: false,
            title: "<div class='alert alert-warning' style='margin-bottom: 0px;'><i class='fas fa-exclamation-triangle fa-fw fa-lg'></i><strong>Warning!</strong></div>",
            message: message,
            buttons: {
                confirm: {
                    label: 'Yes',
                    className: 'btn-success'
                },
                cancel: {
                    label: 'Cancel',
                    className: 'btn-danger'
                }
            },
            callback: (result) => {
                if (result) {
                    this._resetSkill(resetType);
                }
            }
        });
    }

    _resetSkill(resetType) {
        if (resetType == "previous") {
            this.skills = angular.copy(this.currentSkills);
        } else if (resetType == "original") {
            this.skills = angular.copy(this.originalSkills);
        }
    }

    toggleDetailsView() {
        this.viewSkillDetails = !this.viewSkillDetails;
    }

    enter() {
        console.log(this.selected);
        var selectedSkill = { id: 10, name: this.selected, mandatory: false, favorite: false, experience: { years: 0, months: 0 } };
        this._setSkillDisplayValue(selectedSkill);
        this.skills[this.selectedSkillType].push(selectedSkill);
        this.selected = undefined;
    }

    setAllExistingSkills(allSkills) {
        let existingSkillsIds = this.skills ? this.skills.map(skill => { return skill.id }) : [];
        if (this.allExistingSkills) {
            this.allExistingSkills = this.allExistingSkills.filter(skill => {
                if (!existingSkillsIds.includes(skill.id)) {
                    return true;
                }
            });
        }
        if (allSkills) {
            this.allSkills.allExistingSkills = [...this.allExistingSkills];
        } else {
            this.allSkills.allExistingSkills = [];
        }
    }

    editSkills() {
        this.isEditSkills = !this.isEditSkills;
        if (this.skillType === 'certification' || this.skillType === 'education') {
            this.skills = this.skills.filter(skill => skill.degreeName && skill.degreeName != '');
        } else if (this.skillType === 'experience') {
            this.skills = this.skills.filter(skill => skill.title && skill.title != '');
        } else {
            this.skills = this.skills.filter(skill => skill.competency && skill.competency != '');
        }

        if (this.skillsCopy.length === this.skills.length) {
            this.resetSkillsList = [...this.skills];
        } else {
            const deletedSkills = this.skillsCopy.filter(element => 
                !this.skills.some(skill => (skill.id && skill.id === element.id) || (skill.skill === element.skill))
            );
            if(this.skillType != 'certification' && this.skillType != 'education' && this.skillType != 'experience') {
                this.allExistingSkills = [...this.allExistingSkills, ...deletedSkills];
            }
            this.resetSkillsList = [...this.skills];
        }
        
        if(this.skillType != 'certification' && this.skillType != 'education' && this.skillType != 'experience') {
            this.setAllExistingSkills(this.allExistingSkills);
        }
        
        if (this.saveCallback) {
            this.saveCallback.emit(this.allExistingSkills);
        }
        
    }

    deleteSkill(index) {
        this.skills.splice(index, 1);
    }

    onCandidateSkillUpdate(skillIndex, skillToUpdate, skillPriority) {
        if (skillPriority == 'mandatory' && skillToUpdate.favorite == true) {
            skillToUpdate.favorite = false;
        } else if (skillPriority == 'favorite' && skillToUpdate.mandatory == false) {
            skillToUpdate.favorite = true;
        }

        this.skills.splice(skillIndex, 1, skillToUpdate);
    }

    resetSkills() {
        this.skills = [...this.resetSkillsList];
        this.editSkills();
    }

    updateMandatory(skill) {
        skill.mandatory = !skill.mandatory;
        if (skill.mandatory) {
            skill.niceToHave = false;
        }
    }

    updateNiceToHave(skill) {
        skill.niceToHave = !skill.niceToHave;
        if (skill.niceToHave) {
            skill.mandatory = false;
        }
    }

    onEditSkill() {
        this.isEditSkills = !this.isEditSkills;
        this.resetSkillsList = [...this.skills];
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(this.skills, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                this.skills,
                event.previousIndex,
                event.currentIndex,
            );
        }
    }

}
