import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { JobService } from 'src/app/core/services/job.service';
import {
  CdkDragDrop,
  CdkDrag,
  CdkDropList,
  CdkDropListGroup,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-requisition-skills',
  templateUrl: './requisition-skills.component.html',
  styleUrls: ['./requisition-skills.component.css']
})
export class RequisitionSkillsComponent implements OnInit {

  @Input() currentJobStatus;
  @Input() isUploadRequisition;
  @Input() jobTitle;
  @Input() jobLocation;
  @Output() showJobProfileCallback =  new EventEmitter<any>();
  @Output() updateLocationCallback = new EventEmitter<any>();

  allExistingSkills: any = [];
  skills: any = [];
  benefitsNotes: any = '';
  editorConfig: any;
  addAllSkills: any = 'education';
  newSkill: any = {};
  allEducationsList: any = [];
  educationDepartmentList: any = [];
  experiencesList: any = [];
  certificationsList: any = [];
  allSkillsList: any = [];
  ranges: any = [];
  allSkillsObservable: Observable<any>;
  timePeriods: any = [];
  searchSkills: any = [];

  isLoading: boolean = true;

  constructor(
    private jobService: JobService,
    private alertsAndNotificationsService: AlertAndNotificationsService
  ) { 
    this.allSkillsObservable = Observable.create((observer: any) => {
      // Runs on every search 
      observer.next(this.newSkill.competency);
    })
      .pipe(
        mergeMap((token: string) => this.getStatesAsObservable(token))
      );
  }

  ngOnInit() {
    this.editorConfig = {
      toolbar: [
        ['bold', 'italic', 'h1', 'h2', 'h3'],
        ['underline', 'strikeThrough'],
        ['fontSize'],
        ['orderedList', 'unorderedList'],
        ['undo', 'redo'],
        ['paragraph'],
        ['blockquote'],
        ['removeFormat']
      ]
    };
    this.skills = {
      "firstName": "Abdullah",
      "lastName": "Alshawa",
      "title": null,
      "companyName": "Test Corporation",
      "workPhone": null,
      "mobilePhone": "+12015501231",
      "email": "ramm@4dot.com",
      "address": {
        "address1": "address 1",
        "address2": "address 2",
        "country": "US",
        "county": "Denton County",
        "state": "Texas",
        "zipcode": "75056",
        "city": "Lewisville",
        "region": "Lake Oswego",
        "municipality": "Lake Oswego",
        "countryCode": null
      },
      "educations": [
        {
          "degreeName": "Bachelor's",
          "degreeType": "BACHELORS",
          "instituteName": "Civil Engineering",
          "status": "MATCH",
          "endDate": null
        }
      ],
      "experiences": [
        {
          "employerName": "",
          "title": "Software Engineer Intern",
          "duration": 1.1753,
          "status": "MATCH",
          "startDate": {
            "year": 2018,
            "dayOfYear": 182,
            "era": 1,
            "dayOfMonth": 1,
            "dayOfWeek": 7,
            "millisOfDay": 19800000,
            "minuteOfDay": 330,
            "secondOfDay": 19800,
            "yearOfEra": 2018,
            "centuryOfEra": 20,
            "weekyear": 2018,
            "monthOfYear": 7,
            "yearOfCentury": 18,
            "hourOfDay": 5,
            "minuteOfHour": 30,
            "weekOfWeekyear": 26,
            "millisOfSecond": 0,
            "secondOfMinute": 0,
            "chronology": {
              "zone": {
                "fixed": false,
                "uncachedZone": {
                  "fixed": false,
                  "cachable": true,
                  "id": "Asia/Kolkata"
                },
                "id": "Asia/Kolkata"
              }
            },
            "zone": {
              "fixed": false,
              "uncachedZone": {
                "fixed": false,
                "cachable": true,
                "id": "Asia/Kolkata"
              },
              "id": "Asia/Kolkata"
            },
            "millis": 1530403200000,
            "beforeNow": true,
            "equalNow": false,
            "afterNow": false
          }
        }
      ],
      "certifications": [],
      "compensations": [],
      "technicalSkills": [

      ],
      "softSkills": [],
      "extraSkills": [

      ],
      "operationalSkills": [],
      "profileUrl": "http://www.indeed.com/r/Abdullah-Alshawa/da0ae600508326da",
      "profileType": "Indeed",
      "hasResume": true,
      "candidateAdditionalDetails": {
        "salary": {
          "maxSalary": 0,
          "minSalary": 0,
          "salaryType": "SPECIFIC",
          "salaryDuration": null,
          "currency": "USD"
        },
        "sponsorships": [
          "H1"
        ],
        "sponsorshipsNote": "test",
        "willingToRelocate": "YES",
        "relocationNote": null,
        "additionalInformation": null
      },
      "mobilePhoneCountryCodePresent": true,
      "candidateCompanyId": "5c84cedb80ee225c553fd805",
      "phoneIncomplete": false,
      "emailConflicted": false,
      "emailIncomplete": false,
      "phoneConflicted": false
    };
    this.getAllSkills();
    this.setAllSkills();
    this._setExperienceYears();
  }

  _setExperienceYears() {
    let a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
    let b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    for (let i = 1; i < 100; i++) {
      let num: any = i;
      if ((num = num.toString()).length > 9) return 'overflow';
      let n: any = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
      if (!n) return; let str = '';
      str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
      str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
      str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
      str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
      str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + '' : '';
      let yearText = "";
      if (num > 1) {
        yearText = "years of ";
      } else {
        yearText = "year of ";
      }
      let stringText = str + yearText;
      let numText = num + " " + yearText;
      this.timePeriods.push({ name: stringText, value: { years: i, months: 0, range: "" } });
      this.timePeriods.push({ name: numText, value: { years: i, months: 0, range: "" } });
      this.timePeriods.push({ name: 'Min ' + str + yearText, value: { years: i, months: 0, range: "min" } });
      this.timePeriods.push({ name: 'Max ' + str + yearText, value: { years: i, months: 0, range: "max" } });
      this.timePeriods.push({ name: 'Atleast ' + str + yearText, value: { years: i, months: 0, range: "atleast" } });
      this.timePeriods.push({ name: 'Min ' + numText, value: { years: i, months: 0, range: "min" } });
      this.timePeriods.push({ name: 'Max ' + numText, value: { years: i, months: 0, range: "max" } });
      this.timePeriods.push({ name: 'Atleast ' + numText, value: { years: i, months: 0, range: "atleast" } });

    }
  }

  getStatesAsObservable(token: string): Observable<any> {
    const query = new RegExp(token, 'i');
    this.searchSkills = [];
    this.searchSkills = this.getSkill(token);
    return of(
      this.searchSkills.filter((state: any) => {
        return query.test(state.name);
      })
    );
  }

  _getYearsForSearchString(searchText) {
    let yearsResult = this.timePeriods.filter((period) => {
      return period.name.toLowerCase().includes(searchText);
    });
    let yearsObjectArray = [];
    angular.forEach(yearsResult, (year, key) => {
      yearsObjectArray.push({ displayName: year.name, name: year.name });
    });
    return yearsObjectArray;
  }

  getSkill(searchText) {
    let searchResults = [];
    searchText = searchText.toLowerCase();

    if (this._searchStringHasYearsInfoButNoSkill(searchText)) {
      return this._getYearsForSearchString(searchText);
    }

    let periodObject;
    let preText = "";
    let isSearchStringHasYears = false;
    let actualSearchText = "";
    angular.forEach(this.timePeriods, (period, key) => {
      if (searchText.includes(period.name.toLowerCase())) {
        isSearchStringHasYears = true;
        preText = period.name;
        actualSearchText = "";
        actualSearchText = searchText.replace(preText.toLowerCase(), "");
        periodObject = angular.copy(period);
      }
    });

    if (!isSearchStringHasYears) {
      actualSearchText = searchText;
    }

    let validSkills = this.allExistingSkills.filter((skill) => {
      return skill.skill.toLowerCase().includes(actualSearchText);
    });

    if (isSearchStringHasYears && validSkills.length > 0) {
      angular.forEach(validSkills, (skill, key) => {
        searchResults.push({ displayName: preText + skill.skill, name: preText + skill.skill, experience: periodObject.value, mandatory: false, favorite: false, id: skill.id, skill: skill });
      });
    } else if (validSkills.length > 0) {
      angular.forEach(validSkills, (skill, key) => {
        searchResults.push({ displayName: skill.skill, name: skill.skill, experience: skill.experience, mandatory: false, favorite: false, id: skill.id, skill: skill });
      });
    }
    return searchResults;
  }

  _searchStringHasYearsInfoButNoSkill(searchText) {
    let yearsResult = this.timePeriods.filter((period) => {
      return period.name.toLowerCase().includes(searchText);
    });
    return yearsResult.length > 0;
  }

  setAllSkills() {
    this.allEducationsList = [
      { id: 1, name: 'B Tech', department: "", mandatory: false, favorite: false, experience: { years: 1, months: 0 } },
      { id: 2, name: 'M Tech', department: "", mandatory: false, favorite: false, experience: { years: 0, months: 10 } },
      { id: 3, name: 'PHD', department: "", mandatory: false, favorite: false, experience: { years: 2, months: 1 } },
      { id: 4, name: 'Degree', department: "", mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
      { id: 5, name: 'B Tech or equivalent experience', department: "", mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
      { id: 6, name: 'B Pharm', department: "", mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
      { id: 7, name: 'M Pharm', department: "", mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
      { id: 8, name: 'M Tech or equivalent experience', department: "", mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
      { id: 9, name: 'B Com', department: "", mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
      { id: 10, name: 'PHD or equivalent experience', department: "", mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
      { id: 11, name: 'Bachelors or equivalent experience', department: "", mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
      { id: 12, name: 'Masters or equivalent experience', department: "", mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
    ];
    this.educationDepartmentList = [
      { id: 1, name: "Computer Science Engineering" },
      { id: 2, name: "Civil Engineering" },
      { id: 3, name: "Information Technology" },
      { id: 4, name: "Cyber security" },
      { id: 5, name: "Machine Learning" },
      { id: 6, name: "Computer Graphics" },
      { id: 7, name: "Web Development" },
      { id: 8, name: "Software Engineering" },
      { id: 9, name: "Arts and Sciences" },
      { id: 10, name: "Artificial Intelligence" },
      { id: 11, name: "Computational Biology" },
      { id: 12, name: "Computer Networks" }
    ];
    this.experiencesList = [
      { id: 1, name: 'Software Engineer', mandatory: false, favorite: false, experience: { years: 1, months: 0 } },
      { id: 2, name: 'Developer', mandatory: false, favorite: false, experience: { years: 0, months: 10 } },
      { id: 3, name: 'Front end developer', mandatory: false, favorite: false, experience: { years: 2, months: 1 } },
      { id: 4, name: 'Angular developer', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
      { id: 5, name: 'Angular Js', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
      { id: 6, name: 'Type Script', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
      { id: 7, name: 'JS', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
      { id: 8, name: 'TS', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
      { id: 9, name: 'JQuery', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
      { id: 10, name: 'Node', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
      { id: 11, name: 'Bootstrap', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
      { id: 12, name: 'Java', mandatory: false, favorite: false, experience: { years: 0, months: 0 } }
    ];
    this.certificationsList = [
      { id: 1, name: 'AWS Certified Solutions Architect', mandatory: false, favorite: false, experience: { years: 1, months: 0 } },
      { id: 2, name: 'Certified Ethical Hacker', mandatory: false, favorite: false, experience: { years: 0, months: 10 } },
      { id: 3, name: 'Azure Solutions Architect Expert', mandatory: false, favorite: false, experience: { years: 2, months: 1 } },
      { id: 4, name: 'Google Professional Cloud Architect', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
      { id: 5, name: 'Certified Information Security Manager', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
      { id: 6, name: 'CompTIA Security+', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
      { id: 7, name: 'Oracle Certified Professional', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
      { id: 8, name: 'Java SE 11 Developer', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
      { id: 9, name: 'CISSP', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
      { id: 10, name: 'Certified Kubernetes Administrator', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
      { id: 11, name: 'Certified Web Professional', mandatory: false, favorite: false, experience: { years: 0, months: 0 } },
      { id: 12, name: 'Google Mobile Web Specialist', mandatory: false, favorite: false, experience: { years: 0, months: 0 } }
    ];
    this.ranges = [
      { name: "", value: "" },
      { name: "Atleast", value: "atleast" },
      { name: "Min", value: "min" },
      { name: "Max", value: "max" }
    ];
    this.newSkill = new newSkillObj();
  }

  getAllSkills() {
    let filterObject = {
      searchSkill: '',
      searchByParentSkill: '',
      searchCategory: '',
      sortColumn: 'skill',
      sortDir: 'ASC',
      includeAlternateSkills: false
    };

    this.allExistingSkills = [];
    this.isLoading = true;
    // Remove this method in function when manage skills screen changed added
    this.jobService.getAllSkills(filterObject, (data) => {
      this.allExistingSkills = data ? data.allSkills.filter(skill => skill) : [];
      this.allSkillsList = [...this.allExistingSkills];
      this.allExistingSkills.forEach(skill => {
        skill.competency = skill.skill;
      });
      this.isLoading = false;
    }, error => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      this.isLoading = false;
    });
  }

  skillsSaveCallback(event) {
    if (event && event.length > 0) {
      this.allExistingSkills = event;
    }
  }

  onBenefitsNoteChanged() {

  }

  revertToPreviousVersion() {
    let message = "<p>Are you sure?</p><p>All your changes will be lost and will be put back to the previously saved information.</p>";
    this.alertsAndNotificationsService.showConfirm("Revert to previous", message, true, "warning", () => {
      // Api for reverting changes to last saved version
      this.alertsAndNotificationsService.showBannerMessage('Changes revert to previous successfully', 'success');
    }, () => {
      //do nothing
    })
  }

  revertToOriginalVersion() {
    let message = "<p>Are you sure?</p><p>All your current changes and any changes done since the original version will be lost and you will be returned to the original criteria at the time the Requisition was Activated.</p><p>This change cannot be undone.</p>";
    this.alertsAndNotificationsService.showConfirm("Revert to original", message, true, "warning", () => {
      // Api for reverting changes to orignal version
      this.alertsAndNotificationsService.showBannerMessage('Changes revert to original successfully', 'success');
    }, () => {
      //do nothing
    })
  }

  onAllSkillsChanges() {
    this.newSkill = new newSkillObj();
  }

  updateMandatory(skill) {
    skill.mandatory = !skill.mandatory;
    if (skill.mandatory) {
      skill.niceToHave = false;
    }
  }

  updateNiceToHave(skill) {
    skill.niceToHave = !skill.niceToHave;
    if (skill.niceToHave) {
      skill.mandatory = false;
    }
  }

  addNewSkillBySkillCategory(type, skill) {
    const filteredSearchSkill = this.searchSkills.filter(searchSkill => searchSkill.displayName === skill);
    let filteredSkill;
    if (filteredSearchSkill.length > 0) {
      filteredSkill = this.allSkillsList.find(val => val.skill === filteredSearchSkill[0].skill.skill);
      this.newSkill.fromYear = filteredSearchSkill[0].experience ? filteredSearchSkill[0].experience.years : "";
      this.newSkill.atleastOrMinOrMax = filteredSearchSkill[0].experience ? filteredSearchSkill[0].experience.range : ""
      this.newSkill.competency = filteredSearchSkill[0].skill.skill;
    } else {
      let actualSearchText = skill.toLowerCase();
      let regexWithRangeAndYears = /^(min|max|atleast)\s(\d+)\syears?\sof\s(.+)$/;
      let matchWithRangeAndYears = actualSearchText.match(regexWithRangeAndYears);
      if (matchWithRangeAndYears) {
        this.newSkill.atleastOrMinOrMax = matchWithRangeAndYears[1];
        this.newSkill.fromYear = parseInt(matchWithRangeAndYears[2]);
        this.newSkill.competency = matchWithRangeAndYears[3];
      } else {
        let regexWithOnlyYears = /^(\d+)\syears?\sof\s(.+)$/;
        let matchWithOnlyYears = actualSearchText.match(regexWithOnlyYears);
        if (matchWithOnlyYears) {
          this.newSkill.fromYear = parseInt(matchWithOnlyYears[1]);
          this.newSkill.competency = matchWithOnlyYears[2];
        }
      }
    }

    if (!filteredSkill) {
      this.skills.extraSkills = [...this.skills.extraSkills, ...[this.newSkill]];
      return;
    }

    switch (filteredSkill.category.toLowerCase()) {
      case 'technical':
        this.skills.technicalSkills = [...this.skills.technicalSkills, ...[this.newSkill]];
        break;
      case 'operational':
        this.skills.operationalSkills = [...this.skills.operationalSkills, ...[this.newSkill]];
        break;
      case 'soft':
        this.skills.softSkills = [...this.skills.softSkills, ...[this.newSkill]];
        break;
      default:
        this.skills.extraSkills = [...this.skills.extraSkills, ...[this.newSkill]];
        break;
    }

    if (this.allExistingSkills) {
      this.allExistingSkills = this.allExistingSkills.filter(skill => skill.skill != this.newSkill.competency);
    }
  }

  addNewSkill(type) {
    const newSkillTemp = { ...this.newSkill };
    let alertMessage = '';

    switch (type) {
      case 'education':
        if (this.newSkill.degreeName != '' && this.newSkill.instituteName != '') {
          this.skills.educations = [...this.skills.educations, ...[newSkillTemp]];
        } else {
          alertMessage = "Please add 'Degree Name' and 'Institute Name' for Education.";
        }
        break;
      case 'experience':
        if (this.newSkill.title != '') {
          this.skills.experiences = [...this.skills.experiences, ...[newSkillTemp]];
        } else {
          alertMessage = "Please add 'Title' for Experience.";
        }
        break;
      case 'skill':
        if (this.newSkill.competency != '') {
          this.addNewSkillBySkillCategory(type, this.newSkill.competency);
        } else {
          alertMessage = "Please add 'Skill'.";
        }
        break;
      default:
        break;
    }

    if (alertMessage) {
      this.alertsAndNotificationsService.showAlert('Missing Details', alertMessage, 'warning');
    } else {
      this.newSkill = new newSkillObj();
    }

  }

  onSkillSelect(e: TypeaheadMatch): void {

  }

  showJobProfile() {
    if(this.showJobProfileCallback) {
      this.showJobProfileCallback.emit();
    }
  }

  updateLocation() {
    if(this.updateLocationCallback) {
      this.updateLocationCallback.emit();
    }
  }

}

export class newSkillObj {
  id: any;
  title: any = '';
  degreeName: any = '';
  instituteName: any = '';
  fromYear: any = '';
  plusTo: any = '';
  toYear: any = '';
  competency: any = '';
  mandatory: boolean = false;
  favorite: boolean = false;
  niceToHave: boolean = false;
}
